module.exports = {
  siteTitle: 'Rabeah Abbas | Web Developer',
  siteDescription:
    'I am Front-End Web Developer that loves working directly with clients to build markatable websites and applications.',
  siteKeywords: 'Rabeah Abbas, Front end developer, website design and development',
  siteUrl: 'https://rabeah.me',
  siteLanguage: 'en_US',

  googleVerification: 'UA-112157801-1',

  name: 'Rabeah Abbas',
  location: 'San Diego, CA',
  email: 'hello@rabeah.me',
  socialMedia: [
    {
      name: 'Github',
      url: 'https://github.com/web-dev-rob',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/rabeah-abbas-293016aa',
    },
    {
      name: 'Codepen',
      url: 'https://codepen.io/web-dev-rob/',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/rabeah_abbas/',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '#about',
    },
    {
      name: 'Work',
      url: '#projects',
    },
    {
      name: 'Experience',
      url: '#jobs',
    },
    {
      name: 'Contact',
      url: '#contact',
    },
  ],

  twitterHandle: '',
  googleAnalyticsID: 'UA-112157801-1',

  navHeight: 100,

  greenColor: '#64ffda',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
